@mixin mq($size) {
  @media only screen and (max-width: $size) {
    @content;
  }
}

.footer {
  background-color: #393939;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23444444' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23555555'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .75rem;
  flex-direction: column;
  padding: 6rem 2rem;
  height: var(--footerHeight);

  strong {
    font-weight: 500;
  }

  * {
    color: var(--color-extra03)
  }

  .footer-content {

    h3 {
      font-size: 2.1rem;
    }

    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 90%;
    @include mq(1280px) {
      width: 90%;
    }
    @include mq(768px) {
      width: var(--contentWidth);
    }

    .top {
      .column-1 p {
        font-size: 1.275rem;
        font-weight: 200;
      }

      display: flex;
      justify-content: space-between;
      gap: 2rem;
      @include mq(1080px) {
        flex-wrap: wrap;
        justify-content: center;
      }
      @include mq(650px) {
        display: block;
      }

      .column {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        flex: 1;

        h3 {
          margin: 0;
        }
      }

      .column-1 {
        flex: 1.5 !important;

        .logo-container {
          display: flex;
          align-items: center;
          gap: 1rem;

          .logo-img {
            width: 3.25rem;
          }
        }

        p {
          padding-right: 4rem;
          text-align: justify;
          margin: 0;
        }

        .links {
          display: flex;
          gap: 0.6rem;

          a {
            display: flex;
            align-items: center;
            gap: 0.6rem;
          }
        }
      }

      .column-2 {

        .links {
          display: flex;
          flex-direction: column;
          gap: 0.6rem;

          a {
            display: flex;
            align-items: center;
            gap: 0.6rem;
          }
        }

      }

      @include mq(1080px) {
        .column {
          width: 50% !important;
        }
        .column-1, .column-3 {

        }
      }

      .column-3 {

        .links {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          a {
            display: flex;
            align-items: center;
            gap: 1rem;
            cursor: pointer;
          }
        }
      }

      .column-4 {
        .links {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          a {
            display: flex;
            align-items: center;
            gap: 1rem;
            white-space: nowrap;
          }
        }
      }

      @include mq(768px) {
        justify-content: flex-start !important;
        .column {
          width: 100% !important;
        }
        .column-1, .column-3 {
        }
        .column-1 {
          p {
            padding: 0 !important;
          }
        }
      }
      @include mq(650px) {
        .column {
        }
        .column-1, .column-3 {
        }
      }
      @include mq(580px) {
        .column {
        }
        .column-1, .column-3 {
        }
      }
      @include mq(510px) {
        .column-1 {
          p {
            padding: 0;
          }
        }
        .column {
          width: 100% !important;
        }
        .column-2, .column-3, .column-4 {
        }
      }
      @include mq(420px) {
        .column {
        }
      }
    }

    .bottom {
      display: flex;
      flex-direction: column;
      gap: .5rem;

      * {
        text-align: center;
      }
    }
  }

  .happy {
    display: flex;
    align-items: center;
    justify-content: center;

    .watermark {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: .25rem;

      img {
        width: 22.25px;
        aspect-ratio: 1 / 1;
        margin-left: .25rem !important;
      }

      span {
        color: #f1b44c;
        margin-right: .25rem !important;
        font-weight: 600;
      }

    }
  }
}
